import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";
import logo from '../Assets/logo.png'
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              <img src={logo} style={{ width: "12rem" }} alt="" srcset="" className="navbar-sign" />
            </p>
            <p className="ft-description">
              Talk to online doctors and get medical advice, online
              prescriptions, refills and medical notes within minutes. On-demand
              healthcare services at your fingertips.
            </p>
          </div>

          {/* <SubscribeNewsletter /> */}
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Others</p>
          <ul className="ft-list-items">
            <li>
              <a href="#guidingPrinciple">Guiding Principle</a>
            </li>
            <li>
              <a href="#staff">Staff</a>
            </li>
            <li>
              <a href="#ourValues">Our Values</a>
            </li>
            <li>
              <a href="#missionVission">Mission and Vision</a>
            </li>
            <li>
              <a href="#ourCommitment">Our Commitments</a>
            </li>
          </ul>
        </div>
        {/* 
        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>General Info</Link>
            </li>
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/legal"}>Terms of Services</Link>
            </li>
            <li>
              <Link to={"/legal"}>Consultations</Link>
            </li>
            <li>
              <Link to={"/legal"}>How it Works</Link>
            </li>
          </ul>
        </div> */}

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">

            <li>
              <a href="tspolyclinic.in@gmail.com">
                tspolyclinic.in@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:+919046476855">+91-9046476855</a>
            </li>
            <li>
              <a href="tel:+9103216350140">
                +91-03216 350140</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>©2023 Treatment Solution Polyclinic. All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.youtube.com/@treatmentsolution"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg> */}
              <YouTubeIcon />
            </a>
          </li>

          <li>
            <a
              href="https://www.facebook.com/people/Treatment-solution/100089848560201/?mibextid=ZbWKwL"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/78.206910/?igshid=ZDdkNTZiNTM%3D"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg> */}
              <InstagramIcon />
            </a>
          </li>
        </ul>

        <div>
          <a
            href="mailto:ssayaib@gmail.com"
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "0.8rem", textDecoration: "none", color: "#D0D3DA", borderBottom: "2px solid #D0D3DA" }}
          >
            Developed and Designed by Sayaib Sarkar
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
